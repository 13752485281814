/*----------------------------------------
        Data Tables
------------------------------------------*/
#main .section-data-tables .dataTables_wrapper table.dataTable
{
    width: 100% !important;
}

#main .section-data-tables .dataTables_wrapper table.dataTable thead th
{
    min-width: 69px;

    color: #616161;
}

#main .section-data-tables .dataTables_wrapper table.dataTable thead th.background-image-none
{
    background-image: none !important;
}

#main .section-data-tables .dataTables_wrapper table.dataTable thead th.select-all
{
    background-image: none !important;
}

#main .section-data-tables .dataTables_wrapper table.dataTable th,
#main .section-data-tables .dataTables_wrapper table.dataTable td
{
    font-weight: 300;

    white-space: nowrap;

    border-bottom: 1px solid #cfd8dc;
}

#main .section-data-tables .dataTables_wrapper table.dataTable th
{
    padding: 17px 14px;
}

#main .section-data-tables .dataTables_wrapper table.dataTable tbody
{
    overflow: auto;

    max-width: 100%;
    height: 300px;
}

#main .section-data-tables .dataTables_wrapper table.dataTable tbody th,
#main .section-data-tables .dataTables_wrapper table.dataTable tbody td
{
    white-space: nowrap;

    border: none !important;
}

#main .section-data-tables .dataTables_wrapper table.dataTable tbody td:not(:first-child)
{
    padding: 8px 14px;
}

#main .section-data-tables .dataTables_wrapper table.dataTable tbody tr td:before,
#main .section-data-tables .dataTables_wrapper table.dataTable tbody tr th:before
{
    font-size: .9rem;
    line-height: 14px;

    top: 10px;

    width: 12px;
    height: 12px;

    text-indent: 0;
}

#main .section-data-tables .dataTables_wrapper table.dataTable tfoot th,
#main .section-data-tables .dataTables_wrapper table.dataTable tfoot td
{
    font-weight: 300;

    white-space: nowrap;

    border-top: 1px solid #cfd8dc;
}

#main .section-data-tables .dataTables_wrapper table.dataTable.stripe tbody tr,
#main .section-data-tables .dataTables_wrapper table.dataTable.display tbody tr
{
    height: auto;
}

#main .section-data-tables .dataTables_wrapper table.dataTable.stripe tbody tr .odd,
#main .section-data-tables .dataTables_wrapper table.dataTable.display tbody tr .odd
{
    background-color: #f4f5f7;
}

#main .section-data-tables .dataTables_wrapper table.dataTable.stripe tbody tr .odd > .sorting_1,
#main .section-data-tables .dataTables_wrapper table.dataTable.display tbody tr .odd > .sorting_1
{
    background-color: transparent;
}

#main .section-data-tables .dataTables_wrapper table.dataTable.stripe tbody tr .odd.selected,
#main .section-data-tables .dataTables_wrapper table.dataTable.display tbody tr .odd.selected
{
    background-color: #acbad4 !important;
}

#main .section-data-tables .dataTables_wrapper table.dataTable.stripe tbody tr .odd.selected th,
#main .section-data-tables .dataTables_wrapper table.dataTable.display tbody tr .odd.selected th
{
    border-radius: 0;
}

#main .section-data-tables .dataTables_wrapper table.dataTable.display tbody tr.hover > .sorting_1,
#main .section-data-tables .dataTables_wrapper table.dataTable.display tbody tr:hover > .sorting_1,
#main .section-data-tables .dataTables_wrapper table.dataTable.order-column.hover tbody tr.hover > .sorting_1,
#main .section-data-tables .dataTables_wrapper table.dataTable.order-column.hover tbody tr:hover > .sorting_1
{
    background-color: transparent;
}

#main .section-data-tables .dataTables_wrapper table [type='checkbox'] + span:not(.lever):before,
#main .section-data-tables .dataTables_wrapper table [type='checkbox']:not(.filled-in) + span:not(.lever):after
{
    opacity: .5;
    border-radius: 4px !important;
}

#main .section-data-tables .dataTables_wrapper .dataTables_length#page-length-option_length,
#main .section-data-tables .dataTables_wrapper .dataTables_filter#page-length-option_filter
{
    display: block;
}

#main .section-data-tables .dataTables_wrapper .dataTables_length#page-length-option_length label select,
#main .section-data-tables .dataTables_wrapper .dataTables_filter#page-length-option_filter label select
{
    display: inline-block;

    width: auto;
    height: auto;
}

#main .section-data-tables .dataTables_wrapper .dataTables_length#page-length-option_length label input,
#main .section-data-tables .dataTables_wrapper .dataTables_filter#page-length-option_filter label input
{
    width: auto;
    height: auto;
}

#main .section-data-tables .dataTables_wrapper .dataTables_length,
#main .section-data-tables .dataTables_wrapper .dataTables_filter
{
    display: none;
}

#main .section-data-tables .dataTables_wrapper .dataTables_info
{
    margin-left: 18px;

    color: #616161;
}

#main .section-data-tables .dataTables_wrapper .dataTables_paginate,
#main .section-data-tables .dataTables_wrapper #page-length-option_paginate
{
    margin-right: .35rem;
    margin-bottom: .75rem;
}

#main .section-data-tables .dataTables_wrapper .dataTables_paginate .paginate_button,
#main .section-data-tables .dataTables_wrapper #page-length-option_paginate .paginate_button
{
    margin-top: .25rem;
    padding: .25em .65em;
}

#main .section-data-tables .dataTables_wrapper .dataTables_paginate .paginate_button.current,
#main .section-data-tables .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
#main .section-data-tables .dataTables_wrapper #page-length-option_paginate .paginate_button.current,
#main .section-data-tables .dataTables_wrapper #page-length-option_paginate .paginate_button.current:hover
{
    color: #fff !important;
    border: 1px solid #3f51b5;
    border-radius: 6px;
    background: #3f51b5;
    box-shadow: 0 0 8px 0 #3f51b5;
}

#main .section-data-tables .dataTables_wrapper .dataTables_paginate .paginate_button:hover,
#main .section-data-tables .dataTables_wrapper #page-length-option_paginate .paginate_button:hover
{
    color: #fff !important;
    border: 1px solid #3f51b5;
    border-radius: 6px;
    background: #3f51b5;
    box-shadow: 0 0 8px 0 #3f51b5;
}
